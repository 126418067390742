<template>
  <div class="container">

    <div class="box-title">
      <div class="title-content">
      <!-- <router-link to="/news/1"> -->
        <!-- path:'oneNews', -->
      <router-link :to="{ name: 'onenews', params: { newsid: 20230628 } }">
        <div class="title-line">
          <div class="title-header">
            什么是商用密码？数字时代商用密码为何重要？一文解读
          </div>
          <div class="title-date">
            2023-06-29
          </div>  
        </div>        
        <div class="title-summery">
          7月1日起，新修订的《商用密码管理条例》将正式生效，随着现在数字化进程的加快，商用密码已经应用在老百姓生活的方方面面，为大家提供安全保障。02:11什么是密码？根据《中华人民共和国密码法》的规定，密码是指采用特定变换的方法对信息等进行加密保护、安全认证的技术、产品和服务。举个例子，小张约小王去食堂吃饭，但是不想让别人知道，相互约定以“0”代表小张，“1”代表小王，“7”代表食堂，“9”代表吃..
        </div>
      </router-link>
      </div>
    </div> 

    <div class="box-title">
      <div class="title-content">
        <div class="title-line">
          <div class="title-header">
            网络安全工作的“3保1评”
          </div>
          <div class="title-date">
            2023-02-15
          </div>  
        </div>        
        <div class="title-summery">
          导读： 网络安全已经是关系国家安全的一个重要主权领域，“没有网络安全就没有国家安全，就没有经济社会稳定运行，广大人民群众利益也难以得到保障。”我国近年来通过立法的手段颁布了多项网络安全领域的重要的法律法规。有力的推动我国的网信事业快速发展，并取得历史性成就。网络安全工作的“3保1评”本文主要内容：分保等保关保密评内容来源：军哥系统集成号
        </div>
      </div>
    </div>  

    <div class="box-title">
      <div class="title-content">
        <div class="title-line">
          <div class="title-header">
            轻松过密评小课堂| 密评政策知多少？
          </div>
          <div class="title-date">
            2022-12-23
          </div>  
        </div>        
        <div class="title-summery">
          前两期密评小课堂，我们分享了轻松过密评小课堂 | 为什么要过密评？ 轻松过密评小课堂 | 密评到底“评”什么？，那么，这一讲，我们来聊聊各行业的密评政策！近年来，信息化、网络化、数字化程度不断加深，密码技术已经渗透到了社会生产生活各个方面，重要网络和信息系统、关键信息基础设施、数字化平台都离不开密码的保护。许多行业主管部门近年也出台了一系列政策文件，对密码应用及商用密码应用安全性评估提出了明...
        </div>
      </div>
    </div>  
   
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  // display: flex;  
  // align-items: center;
  min-height: 600px;
  margin-top: 50px;

  justify-content:center;

  .box-title {
    display: flex;    
    // width: 80%;
    justify-content:center;


    // .title-content{
    //   box-sizing: border-box;
    //     // display: flex;
    //   flex-grow: 1;
    //   width: 100%;
    //   .title-header {//
    //     display: block;
    //     margin-top: 0;
    //   }
    //   .title-summery {//
    //     font-size: 12px;
    //     margin-top: 10px;
    //   }
    // }
    .title-content{
      width: 80%;      
      justify-content:center;
      .title-line {
        width: 100%;
        // border-bottom: 2px #999 solid;
        
        margin-top: 20px;
        // margin-bottom: 20px;
        // align-items: center;
        display: flex;

        .title-header {//
          margin-bottom: -2px;
          display: flex;
          // flex-direction: row;
          // align-items: center;
          width: 90%;
          // line-height: 32px;
          color: #333;
          // font-size: 18px;
          // font-weight: 400;
          text-shadow: none;
          font-size: 16px;
          // font-weight: bolder;
          font-family: 微软雅黑;
          // border-bottom: 2px #ac0000 solid;
        }
        .title-date {
          margin-bottom: 10px;
          display: block;
          // flex-direction: row;
          // align-items: center;
          width: 15%;
          line-height: 32px;
          color: #333;
          // font-size: 18px;
          // font-weight: 400;
          text-shadow: none;
          font-size: 16px;
          // font-weight: bolder;
          font-family: 微软雅黑;
          // border-bottom: 2px #ac0000 solid;
        }
      }
      .title-summery {
        margin-bottom: 20px;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        width: 100%;
        line-height: 32px;
        color: #333;
        // font-size: 18px;
        // font-weight: 400;
        text-shadow: none;
        font-size: 14px;
          text-align: left;
        // font-weight: bolder;
        font-family: 微软雅黑;
        // border-bottom: 2px #ac0000 solid;
      }
    }
  }
  // h4{
  //   display: block;
  //   margin-block-start: 1.33em;
  //   margin-block-end: 1.33em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  // }
}
</style>
